<script setup>
  import { storeToRefs } from 'pinia'
  import { computed, ref } from 'vue'
  import { event } from 'vue-gtag'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  import AgreementField from '@/components/Forms/AgreementField.vue'
  import EmailField from '@/components/Forms/EmailField.vue'
  import NameField from '@/components/Forms/NameField.vue'
  import PhoneField from '@/components/Forms/PhoneField.vue'
  import { useCartStore } from '@/stores/cart'
  import { useCartCookieFieldsStore } from '@/stores/cartCookieFields'
  import { useGeneralStore } from '@/stores/general'
  import { parseUtm } from '@/utils/utm'

  // Todo: props text button
  const props = defineProps({
    pageId: Number,
    activeCampaignId: Number,
    color: {
      type: String,
      default: '#8444F6',
    },
    btnText: {
      type: String,
      default: 'Залишити'
    },
    btnWrapperClass: {
      type: String,
      default: null
    },
    btnClass: {
      type: String,
      default: null
    }
  })

  const emit = defineEmits(['afterCreatedPreOrder'])

  const generalStore = useGeneralStore()
  const cartStore = useCartStore()
  const cartCookieFieldsStore = useCartCookieFieldsStore()

  const htmlFormPreorder = ref(null)
  const phoneFieldRef = ref()
  const phoneFieldClass = ref('')
  const agreement = ref(true)

  const { name, phone, email } = storeToRefs(cartCookieFieldsStore)

  const isDisabled = computed(() => !agreement.value || cartStore.isPreOrderModalLoading)

  function changeClassPhoneFieldAfterValidation(val) {
    if (val === true) {
      phoneFieldClass.value = ''
    }
  }

  async function formSubmit() {
    phoneFieldRef.value.$refs.telInputRef.onInput()

    if (cartStore.phoneIsValid !== true) {
      phoneFieldClass.value = 'shake invalid'
      setTimeout(() => {
        phoneFieldClass.value = 'invalid'
      }, 500)

      phoneFieldRef.value.$refs.telInputRef.focus()

      return
    }

    phoneFieldClass.value = ''

    if (htmlFormPreorder.value.checkValidity()) {
      event('submit_form_preorder', {
        category: 'Action-To-Preorder',
        action: 'submit',
        value: 0,
        label: 'Submit Form For Preorder',
        campaign_id: props.activeCampaignId,
        page_id: props.pageId,
      })

      fbq('track', 'Lead');

      const formRequest = {
        page_id: props.pageId,
        campaign_id: props.activeCampaignId,
        customer: {
          name: name.value,
          phone: phone.value,
          email: email.value,
        },
      }

      const queryString = window.location.search
      parseUtm(queryString, formRequest)
      // Todo: ab_field

      generalStore.startPreOrderModalLoading()
      generalStore
        .preorder(formRequest)
        .then(async (response) => {
          emit('afterCreatedPreOrder', {
            data: response.data,
          })


          generalStore.openThanksModal(response.data.data.url)
        })
        .catch((err) => {
          console.log('err', err)
          // Todo: error modal
          alert('Упс.., сталася помилка, спробуйте ще раз')
        })
        .finally(() => {
          generalStore.stopPreOrderModalLoading()
        })
    }
  }
</script>

<template>
  <form
    ref="htmlFormPreorder"
    autocomplete="off"
    @submit.prevent="formSubmit">
    <NameField v-model="name" />
    <PhoneField
      ref="phoneFieldRef"
      v-model="phone"
      :class="phoneFieldClass"
      @is-validated="changeClassPhoneFieldAfterValidation" />
    <EmailField v-model="email" />

    <AgreementField v-model="agreement" />

    <div class="cart-submit" :class="props.btnWrapperClass">
      <button
        :style="{ background: props.color }"
        type="submit"
        class="cart-submit--btn"
        :class="props.btnClass"
        :disabled="isDisabled">
        <PulseLoader
          style="padding-right: 10px"
          size="12px"
          :loading="generalStore.isPreOrderModalLoading"
          color="#fff" />
        {{ props.btnText }}
        <slot name="purchase"></slot>
      </button>
    </div>
  </form>
</template>

<style lang="scss">
  .cart-submit--btn {
    &:disabled {
      opacity: 50%;
    }
  }

  // Todo: sale
  .cart-total.sale {
    padding-top: 0px;
  }

  .cart-total--value.sale {
    color: #f84147;
  }

  .cart-old--value {
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;
    color: #999;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;

    &:before {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      top: 50%;
      height: 2px;
      transform: translateY(-50%);
      background-color: #999;
    }
  }
</style>
