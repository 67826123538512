import { createApp } from 'vue/dist/vue.esm-bundler';
// import { createRouter } from "vue-router";
import * as Sentry from "@sentry/vue";
import { createPinia } from 'pinia'

// import App from './App.vue'
// import router from './router'

import VueGtag from "vue-gtag";
import VueTelInput from 'vue-tel-input'
import VueLazyload from 'vue-lazyload'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// global styles
import 'vue3-carousel/dist/carousel.css'
import 'vue-tel-input/vue-tel-input.css'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'


import { defineAsyncComponent } from "vue";

// hotme
// const HotmeHome = defineAsyncComponent(() => import('@/templates/courses/hotme/js/Pages/HotmeHome.vue'))
// const HotmeTariffs = defineAsyncComponent(() => import('@/templates/clubs/profit/js/Pages/HomeProfit.vue'));
// const HotmePreorder = defineAsyncComponent(() => import('@/templates/clubs/profit/js/Pages/HomeProfit.vue'));

// helps
import PurchaseController from '@/components/Cart/PurchaseController.vue';
import ModalPreorder from '@/components/Cart/ModalPreorder.vue';
import ModalThanks from '@/components/Cart/ModalThanks.vue';
import PreorderForm from '@/components/Forms/PreorderForm.vue';
// Todo: modal thanks
import ButtonBuy from '@/templates/courses/fixme/js/Components/ButtonBuy.vue';
import ButtonPreorder from '@/templates/courses/fixme/js/Components/ButtonPreorder.vue';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// components
// Todo: change components set
const FixmeHome = defineAsyncComponent(() => import('@/templates/courses/fixme/js/Pages/FixmeHome.vue'))
const FixmeCurator = defineAsyncComponent(() => import('@/templates/courses/fixme/js/Pages/FixmeCurator.vue'))
const ProfitHome = defineAsyncComponent(() => import('@/templates/clubs/profit/js/Pages/ProfitHome.vue'))
const LeaveProcessor = defineAsyncComponent(() => import('@/components/Leave/LeaveProcessor.vue'))

const app = createApp({});
// const router = createRouter({});

// Sentry
Sentry.init({
  app,
  // Todo: use env
  dsn: "https://e60654238190503ccf06c352b922c644@o4507974920372224.ingest.de.sentry.io/4507974922666064",
  environment: import.meta.env.VITE_APP_SERVER_ENV,
  release: "vernerfit@" + __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/vernerfit\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});

app
  // .use(router)
  .use(pinia)
  .use(Toast, {})
  .use(VueTelInput)
  .use(VueLazyload, {
    preLoad: 2.1,
  })
  .use(VueGtag, {
    // Todo: move to env
    config: { id: "G9B215VQBS" }
  })

app.component('FixmeHome', FixmeHome)
app.component('FixmeCurator', FixmeCurator)
app.component('ProfitHome', ProfitHome)
// app.component('HotmeHome', HotmeHome)
app.component('preorder-form', PreorderForm)
app.component('modal-preorder', ModalPreorder)
app.component('modal-thanks', ModalThanks)
app.component('purchase-controller', PurchaseController)
app.component('button-buy', ButtonBuy)
app.component('button-preorder', ButtonPreorder)
app.component('leave-controller', LeaveProcessor)

app.mount('#app')

// export default app
