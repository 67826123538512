import { defineStore } from "pinia";
import { useProductStore } from "./products.js";
import currencies from "@/enums/currencies";
import { paymentMethods } from "@/enums/paymentMethods";
import { offers } from '@/enums/offers'
import order from "@/api/order";

export const useCartStore = defineStore({
  id: "cart",
  persist: false,
  state: () => ({
    contents: {},
    isPurchaseLoading: false,
    currency: currencies.uah,
    paymentMethod: paymentMethods.bank,
    offerType: offers.simple,
    phoneIsValid: null,
    certificate: {
      isApply: false,
      value: null,
      error: false,
      activated: false,
    },
    promocode: {
      value: null,
      isApply: false,
      prices: {
        uah: null,
        eur: null
      },
      error: false,
      activated: false,
    }
  }),
  getters: {
    count() {
      return Object.keys(this.contents).reduce((acc, id) => {
        return acc + this.contents[id].quantity;
      }, 0);
    },

    promocodeIsApply(state) {
      return state.promocode.isApply
    },
    specialOfferIsApply(state) {
      return state.offerType === offers.special
    },
    discountIsApply() {
      return this.promocodeIsApply || this.specialOfferIsApply
    },

    totalCurrentCurrency(state) {
      const products = useProductStore();
      const value = Object.keys(this.contents).reduce((acc, id) => {
        const propName = this.currency === "UAH" ? "priceUah" : "priceEur";
        return acc + products.items[id][propName] * this.contents[id].quantity;
      }, 0);

      if (state.promocode.isApply) {
        // Todo: refactor to use product store for calculate discount
        const discountValue = this.currency === 'UAH' ? this.promocode.prices.uah : this.promocode.prices.eur
        return value - discountValue
      }

      if (state.offerType === offers.special) {
        return this.currency === 'UAH' ? this.specialOfferInfo.priceUah : this.specialOfferInfo.priceEur
      }

      return value
    },

    originalPrice() {
      const products = useProductStore();
      let res =  Object.keys(this.contents).reduce((acc, id) => {
        const propName = this.currency === "UAH" ? "priceUah" : "priceEur";
        return acc + products.items[id][propName] * this.contents[id].quantity;
      }, 0);

      if (this.specialOfferIsApply) {
        let specialOfferRes = Object.keys(this.contents).reduce((acc, id) => {
          const propName = this.currency === "UAH" ? "specialOfferOldPriceUah" : "specialOfferOldPriceEur";
          return acc + products.items[id][propName] * this.contents[id].quantity;
        }, 0);

        return res + specialOfferRes
      }

      return res
    },

    currencySymbol() {
      return this.currency === currencies.uah ? "₴" : "€";
    },

    formattedCart() {
      const products = useProductStore();

      if (!products.loaded)
        return [];

      return Object.keys(this.contents).map((productId) => {
        const purchase = this.contents[productId];

        return {
          id: purchase.productId,
          // image: products.items[purchase.productId].image,
          name: products.items[purchase.productId].name,
          showName: products.items[purchase.productId].showName,
          // canApplyPromo: products.items[purchase.productId].canApplyPromo,
          // canApplyCertificate: products.items[purchase.productId].canApplyCertificate,
          quantity: purchase.quantity,
          costUAH: purchase.quantity * products.items[purchase.productId].priceUah,
          costEUR: purchase.quantity * products.items[purchase.productId].priceEur
        };
      });
    },

    isCanApplyCertificate() {
      const products = useProductStore()

      if (!products.loaded)
        return false;

      if (this.specialOfferIsApply) return false

      return Object.keys(this.contents).some((productId) => products.items[productId].canApplyCertificate)
    },
    isCanApplyPromocode() {
      const products = useProductStore();

      if (!products.loaded)
        return false;

      if (this.specialOfferIsApply) return false

      return Object.keys(this.contents).some((productId) => products.items[productId].canApplyPromo)
    },
    isCanApplySpecialOffer() {
      const products = useProductStore()

      if (!products.loaded) return false

      return Object.keys(this.contents).some((productId) => products.items[productId].hasSpecialOffer)
    },

    specialOfferInfo(state) {
      const products = useProductStore()

      if (!products.loaded) return null
      if (!this.isCanApplySpecialOffer) return null

      const firstProductId = Object.keys(state.contents)[0]
      // console.log(firstProductId, products.items[firstProductId])

      return {
        noSpecialOfferLabel: products.items[firstProductId].noSpecialOfferLabel,
        specialOfferLabel: products.items[firstProductId].specialOfferLabel,
        specialOfferDiscountLabel: products.items[firstProductId].specialOfferDiscountLabel,
        specialOfferDescription: products.items[firstProductId].specialOfferDescription,
        priceUah: products.items[firstProductId].specialOfferPriceUah,
        priceEur: products.items[firstProductId].specialOfferPriceEur,
        // Todo: total price
      }
    }
  },

  actions: {
    setCurrency(currency) {
      this.currency = currency
    },
    setPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;

      if (paymentMethod !== paymentMethods.certificate) {
        this.resetCertificate();
      }
    },
    reset() {
      this.contents = {};
    },
    one(productId) {
      // clear others products
      this.contents = {};
      this.certificate.isApply = false;

      // if (this.contents[productId]) {
      //     // Only 1 product per cart
      //     this.contents[productId].quantity = 1
      // }

      this.contents[productId] = {
        productId,
        quantity: 1
      };
    },
    add(productId) {
      if (this.contents[productId]) {
        // Only 1 product per cart
        this.contents[productId].quantity += 1;
      } else {
        this.contents[productId] = {
          productId,
          quantity: 1
        }
      }
    },
    remove(productId) {
      if (!this.contents[productId]) return

      this.contents[productId].quantity -= 1

      if (this.contents[productId].quantity === 0) delete this.contents[productId]
    },
    setOfferType(offerType) {
      this.offerType = offerType

      if (offerType === offers.special) {
        this.resetPromocode()
        this.resetCertificate()
        this.resetCurrency()

        if (this.paymentMethod === paymentMethods.certificate) {
          this.resetPaymentMethod()
        }
      }
    },
    setApplyPromocode(priceEur, priceUah) {
      this.promocode.isApply = true
      this.promocode.prices.eur = priceEur
      this.promocode.prices.uah = priceUah
    },
    setCertificateValue(value) {
      this.certificate.value = value
    },
    setApplyCertificate() {
      this.certificate.isApply = true
    },
    resetCurrency() {
      this.currency = currencies.uah
    },
    resetCertificate() {
      this.certificate.isApply = false
      this.certificate.value = null
      this.certificate.error = false
      this.certificate.activated = false
    },
    resetOfferType() {
      this.offerType = offers.solo
    },
    resetPromocode() {
      this.promocode.isApply = false
      this.promocode.value = null
      this.promocode.prices = {
        uah: null,
          eur: null
      }
      this.promocode.error = false
      this.promocode.activated = false;
    },
    setPromocodeValue(value) {
      this.promocode.value = value;
    },
    resetPaymentMethod() {
      this.paymentMethod = paymentMethods.bank
    },
    resetPhoneIsValid() {
      this.phoneIsValid = null;
    },
    enablePurchaseLoading() {
      this.isPurchaseLoading = true;
    },
    disablePurchaseLoading() {
      this.isPurchaseLoading = false;
    },
    setCertificateError(val) {
      this.certificate.error = val;
    },
    setCertificateActivated(val) {
      this.certificate.activated = val;
    },
    setPromoCodeError(val) {
      this.promocode.error = val;
    },
    setPromoCodeActivated(val) {
      this.promocode.activated = val;
    },
    setPhoneIsValid(val) {
      this.phoneIsValid = val;
    },
    async purchase(form) {
      return await order.create(form);
    },

    async applyPromocode(promocodeValue, productId, campaignId) {
      return await order.applyPromocode({
        product: productId,
        promocode: promocodeValue,
        campaign_id: campaignId
      });
    },
    async applyCertificate(certificateValue, productId, campaignId) {
      return await order.applyCertificate({
        product: productId,
        certificate: certificateValue,
        campaign_id: campaignId
      });
    }
  }
});
